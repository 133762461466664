<script>
  import { mapActions, mapMutations, mapGetters } from "vuex";
  export default {
    components: {
      Carrousel: () => import("./CompetitionCarrousel.vue"),
      Card: () => import("./Card.vue"),
      ListViewers: () => import("./ListViewers.vue"),
      ModalCompetition: () => import("./ModalCompetition.vue"),
    },
    data() {
      return {
        isLoading: true,
        page: 0,
        hasMore: false,
        showModalViewers: false,
        totalViewersContest: 0,
        selectedParticipant: null,
        objCurrentAwads: null,
      };
    },
    computed: {
      ...mapGetters("competitions", ["getCompetition", "getRanking"]),
      competitionId() {
        return this.$route.params.id;
      },
      best() {
        return this.getRanking.slice(0, this.getCompetition.totalPodium);
      },
      participants() {
        return this.getRanking.slice(this.getCompetition.totalPodium);
      },
      positionPodium() {
        return this.getCompetition.totalPodium + 1;
      },
      userIsAdmin() {
        return this.$userData.role === "superadmin";
      },
      showComments() {
        return this.userIsAdmin || this.getCompetition?.policiesInteration?.users.includes(this.$userData._id);
      },
      commentsData() {
        if (!this.showComments) {
          return [];
        }
        return this.$store.state.comments.comment;
      },
    },
    watch: {
      competitionId() {
        this.changeCompetition();
      },
    },
    methods: {
      ...mapActions("competitions", ["getCompetitionById", "getRankingDb"]),
      ...mapActions(["setItemViewed", "getItemViewersTotal"]),
      ...mapActions("comments", ["getCommentsByModel"]),
      ...mapMutations("competitions", ["setRanking", "setNewRanking"]),
      async setRankingData() {
        const { hasMore, ranking = [] } = await this.getRankingDb({ contestId: this.competitionId, query: `page=${this.page}` });

        this.hasMore = hasMore;
        this.setRanking(ranking);
      },
      async setMoreRankingData() {
        if (!this.hasMore || this.isLoading) {
          return;
        }

        this.isLoading = true;
        this.page++;

        const { hasMore, ranking = [] } = await this.getRankingDb({ contestId: this.competitionId, query: `page=${this.page}` });

        this.hasMore = hasMore;
        this.setNewRanking(ranking);
        this.isLoading = false;
      },
      async changeCompetition() {
        this.isLoading = true;
        this.page = 0;
        this.hasMore = false;

        await this.getCompetitionById({ _id: this.competitionId });
        await this.setRankingData();
        await this.getTotalViewersContest(this.competitionId);
        this.setNewView(this.competitionId);

        this.isLoading = false;
      },
      getParticipantAward(position) {
        if (this?.getCompetition.positionByRange) {
          position++;
          return this.getCompetition.awards.find((element) => position >= element.positionStart && position <= element.positionEnd);
        } else {
          if (this.getCompetition.awards && position >= 0 && position < this.getCompetition.awards.length) {
            return this.getCompetition.awards[position];
          }
        }
      },
      async getTotalViewersContest(itemId) {
        this.totalViewersContest = await this.getItemViewersTotal(itemId);
      },
      showUsersView(mode) {
        this.showModalViewers = mode;
      },
      setModeScroll(mode = "initial") {
        if (!document) {
          return;
        }
        document.body.style.overflowY = mode;
      },
      async showModalDetails(participant = null, award = null) {
        this.selectedParticipant = participant;
        this.objCurrentAwads = award;

        if (!participant) {
          return;
        }
        if (this.showComments) {
          await this.getCommentsByModel(participant._id);
        }

        this.setNewView(participant._id);
      },
      setNewView(itemId) {
        if (!["active", "finished"].includes(this.getCompetition?.status)) {
          return;
        }
        this.setItemViewed(itemId);
      },
    },
    beforeMount() {
      this.changeCompetition();
    },
  };
</script>

<template>
  <div class="rankingList">
    <div class="rankingList__views" @click="showUsersView(true)" v-if="totalViewersContest && userIsAdmin">
      <iconic name="eye_contets" class="competitions__eye" />
      <span>{{ totalViewersContest }}</span>
    </div>
    <Carrousel
      :best="best"
      :awards="getCompetition.awards"
      :subtitle="getCompetition.description"
      :key="0"
      :modal="true"
      :statusRange="getCompetition.positionByRange"
      @showModalDetails="showModalDetails"
      v-if="best.length" />
    <div class="rankingList__cardContainer">
      <Card
        v-for="(participant, idx) in participants"
        :key="participant.user._id"
        :idxCard="idx"
        :participant="participant"
        :award="getParticipantAward(idx + 3)"
        :position="idx + positionPodium"
        @showModalDetails="showModalDetails" />
    </div>
    <button class="click-on-bottom" v-if="hasMore && !isLoading" @click="setMoreRankingData">Ver más</button>
    <ListViewers :competitionId="competitionId" :close="() => showUsersView(false)" v-if="showModalViewers" />
    <ModalCompetition v-if="selectedParticipant" :participant="selectedParticipant" :award="objCurrentAwads" :comments="commentsData" :showComments="showComments" @close="showModalDetails()" />
  </div>
</template>

<style lang="scss" scoped>
  .rankingList {
    position: relative;
    width: 100%;
    &__cardContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      overflow: hidden;
      gap: 10px;
      padding: 0 0.2rem;
      min-width: 100%;
      margin: 1rem 0;
    }
    &__views {
      position: absolute;
      top: $space-24;
      right: 0;
      @include Flex(row);
      width: fit-content;
      gap: 10px;
      padding: 5px 10px;
      font-weight: 600;
      color: $chicago;
      border-radius: 15px;
      cursor: pointer;
      transition: 0.2s ease-in-out;
      &:hover {
        background-color: rgba(0, 0, 0, 0.06);
      }
    }
    @media (min-width: 500px) {
      &__cardContainer {
        grid-template-columns: repeat(3, 1fr);
      }
    }
    @media (min-width: 1440px) {
      &__cardContainer {
        grid-template-columns: repeat(5, 1fr);
      }
    }
  }
</style>
